import 'owl.carousel';

jQuery( document ).ready( function ( $ ) {
	$( '#videos .owl-carousel' ).owlCarousel(
		{
			items: 2,
			loop: false,
			dots: false,
			nav: true,
			navText: ['<i class="fal fa-arrow-left" aria-hidden="true"></i>', '<i class="fal fa-arrow-right" aria-hidden="true"></i>'],
			stagePadding: 30,
			margin: 30,
			responsive: {
				0:
					{
						items: 1,
					},
				768:
					{
						items: 2,
					}
			}
		}
	);

	$( '#videos .item a' ).on( 'click', function ( e ) {
		e.preventDefault();
		const video_id = $( this ).attr( 'data-video-id' );

		$( '#video-player iframe' ).attr( 'src', 'https://www.youtube.com/embed/' + video_id + '?autoplay=1' );
	} );

	$( '.newsletter form' ).on( 'submit', function ( e ) {
		e.preventDefault();
		var data = $( this ).serialize();
		$.ajax( {
			        type: 'POST',
			        url: 'https://subs.sonymusicfans.com/submit',
			        dataType: 'json',
			        data: data,
			        xhrFields: {
				        withCredentials: false
			        },
			        success: function ( data ) {
				        $( '.newsletter form' ).html( '<p classa="thank-you">Thank you for subscribing!</p>' );
			        },
			        error: function ( err ) {
				        alert( 'An error has occurred!' );
			        }
		        } );
	} );
} );
